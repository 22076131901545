import * as Components from './../../../../Components';
import { TypeOfAuth } from './../menu-auth-type';
import HomeIcon from '@material-ui/icons/Home';
import ArchiveIcon from '@material-ui/icons/Archive';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AirplayIcon from '@material-ui/icons/Airplay';
import Dashboard from './../../../../Components/internal/dashboard/dashboard';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ForumIcon from '@material-ui/icons/Forum';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PieChartIcon from '@material-ui/icons/PieChart';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroIcon from '@material-ui/icons/Euro';
import DescriptionIcon from '@material-ui/icons/Description';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import SpeedIcon from '@material-ui/icons/Speed';
import WebIcon from '@material-ui/icons/Web';
import BuildIcon from '@material-ui/icons/Build';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import SendIcon from '@material-ui/icons/Send';
import SmsIcon from '@material-ui/icons/Sms';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import WorkIcon from '@material-ui/icons/Work';
import BookIcon from '@material-ui/icons/Book';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PaymentIcon from '@material-ui/icons/Payment';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
export default [
    // {
    //     label: 'Home',
    //     path: '/',
    //     exact: true,
    //     component: Components.Dashbord,
    //     authType: TypeOfAuth.Auth,
    //     hidden: true,
    //     icon: <HomeIcon />
    // },
    {
        label: 'eWIP',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <LoyaltyIcon />,
        children: [
            {
                label: 'Home',
                path: '/eWIPDashboard',
                exact: true,
                component: Components.EWIPDashboardTabComponent,
                authType: TypeOfAuth.Auth,
                icon: <HomeIcon />
            },
            {
                label: 'Dashboard',
                exact: true,
                icon: <DashboardIcon />,
                authType: TypeOfAuth.Auth,
                dontFlatListChildren: true,
                isDynamicChildren: 'eWipDashboard',
                children: [
                    // {
                    //     label: 'Service Advisor',
                    //     path: '/dashboard/sa',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="2" />;
                    //     }
                    // },
                    // {
                    //     label: 'Awaiting Arrival',
                    //     path: '/dashboard/aa',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="3" />;
                    //     }
                    // },
                    // {
                    //     label: 'Workshop Control',
                    //     path: '/dashboard/wc',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="4" />;
                    //     }
                    // },
                    // {
                    //     label: 'Parts Advisor',
                    //     path: '/dashboard/pa',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="5" />;
                    //     }
                    // },
                    // {
                    //     label: 'Technician App',
                    //     path: '/dashboard/ta',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="8" />;
                    //     }
                    // },
                    // {
                    //     label: 'Back Office',
                    //     path: '/dashboard/bo',
                    //     exact: true,
                    //     authType: TypeOfAuth.Auth,
                    //     component: () => {
                    //         return <Components.WorkflowDash workFlowId="6" />;
                    //     }
                    // }
                ]
            },

            // {
            //     label: 'QC Jobs',
            //     // hidden: true,
            //     icon: <AirplayIcon />,
            //     exact: true,
            //     path: '/qc_jobs',
            //     component: Components.QCJobsDashboard,
            //     authType: TypeOfAuth.Auth
            // },
            {
                label: 'Reports ',
                exact: true,
                authType: TypeOfAuth.Auth,
                children: [
                    // {
                    //     label: 'Workflows Average Times',
                    //     path: '/internal/dashboard/WorkflowAvgTimeDash',
                    //     exact: false,
                    //     component: Components.WorkflowAvgTimeDash,
                    //     authType: TypeOfAuth.Auth
                    // },
                    {
                        label: 'Return Reasons',
                        path: '/internal/dashboard/ReturnReasonList',
                        exact: false,
                        component: Components.ReturnReasonReportsList,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Return Reason Dashboard',
                        path: '/internal/dashboard/ReturnReasonDashboard',
                        exact: false,
                        component: Components.ReturnReasonDashboard,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Workflows Average Times',
                        path: '/internal/dashboard/WorkflowAvgTimeDash',
                        exact: false,
                        component: Components.WorkflowAvgTimeDash,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'QC Jobs',
                        // hidden: true,
                        icon: <AirplayIcon />,
                        exact: true,
                        path: '/qc_jobs',
                        component: Components.QCJobsDashboard,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },

            {
                label: 'Workshop Loading',
                icon: <DriveEtaIcon />,
                exact: true,
                path: '/workshopLoadingStatusCode',
                component: () => <Components.WorkshopLoadingStatusCode />,
                authType: TypeOfAuth.Auth
            },
            {
                label: 'Others',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <ForumIcon />,
                children: [
                    {
                        label: 'Documents',
                        path: '/viewwipdocuments',
                        exact: true,
                        component: Components.WIPDocumentsViewer,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Archived Jobs',
                        path: '/archivedjobs',
                        exact: true,
                        component: Components.ArchivedJobs,
                        authType: TypeOfAuth.Auth,
                        icon: <ArchiveIcon />
                    },
                    {
                        label: 'After Sales Old Jobs',
                        path: '/AfterSalesOldJobs',
                        exact: true,
                        component: Components.AfterSalesOldJobs,
                        authType: TypeOfAuth.Auth,
                        icon: <ArchiveIcon />
                    },
                    {
                        label: 'Service24 Jobs',
                        path: '/service24jobs',
                        exact: true,
                        component: Components.Service24Jobs,
                        authType: TypeOfAuth.Auth,
                        children: [
                            {
                                label: 'All Jobs',
                                path: '/Service24Jobs/AllJobs',
                                exact: true,
                                authType: TypeOfAuth.Auth,
                                component: () => {
                                    return <Components.Service24Jobs IsUnallocated={false} />;
                                }
                            },
                            {
                                label: 'Unallocated Jobs',
                                path: '/Service24Jobs/UnallocatedJobs',
                                exact: true,
                                authType: TypeOfAuth.Auth,
                                component: () => {
                                    return <Components.Service24Jobs IsUnallocated={true} />;
                                }
                            }
                        ]
                    },

                    {
                        label: 'Special Equipments',
                        exact: true,
                        authType: TypeOfAuth.Auth,
                        children: [
                            {
                                label: 'Dashboard',
                                path: '/Lists/SpecialEquipment/Dash',
                                exact: false,
                                component: Components.SpecialEquipmentDash,
                                authType: TypeOfAuth.Auth
                            },
                            {
                                label: 'Special Equipments',
                                path: '/Lists/SpecialEquipment',
                                exact: false,
                                component: Components.SpecialEquipment,
                                authType: TypeOfAuth.Auth
                            }
                        ]
                    },
                    {
                        label: 'Videos',
                        path: '/Lists/Videos',
                        exact: true,
                        component: Components.Videos,
                        authType: TypeOfAuth.Auth,
                        icon: <VideoLibraryIcon />
                    }
                ]
            }
        ]
    },
    {
        label: 'Dashboard Detail',
        path: '/dashgrid',
        exact: true,
        component: Components.dashboard,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Parts Dashboard Detail',
        path: '/PartsByCreator',
        exact: true,
        component: Components.PartsByCreatorListComponent,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'New Sales List',
        path: '/NewSalesListView',
        exact: true,
        component: Components.NewSalesListView,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Used Sales List',
        path: '/UsedSalesListView',
        exact: true,
        component: Components.UsedSalesListView,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Communicator',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ForumIcon />,
        children: [
            {
                label: 'Dashboard',
                path: '/communicator_dash',
                exact: true,
                component: Components.CustCommunicatorDash,
                authType: TypeOfAuth.Auth,
                icon: <HomeIcon />
            },
            {
                label: 'Open Action Log',
                path: '/Openactionlog',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: Components.OpenActionLog,
                icon: <BookIcon />
            },

            {
                label: 'Site Vehicles',
                path: '/SiteVehicles',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.SiteVehicles status="S" />,
                icon: <EmojiTransportationIcon />
            },
            {
                label: 'VOR Vehicles',
                path: '/VORVehicles',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.SiteVehicles status="VOR" />,
                icon: <DepartureBoardIcon />
            },
            {
                label: 'Workshop Vehicles',
                path: '/WorkshopVehicles',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.SiteVehicles status="WORKSHOP" />,
                icon: <WorkIcon />
            },
            {
                label: 'Check In - WSL not set',
                path: '/CheckInWSLNotSet',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.SiteVehicles status="WSLNOTSET" />,
                icon: <WorkIcon />
            },
            {
                label: 'Jobs Approved',
                path: '/JobsApproved',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: () => <Components.SiteVehicles status="A" />,
                icon: <WorkIcon />
            },
            {
                label: 'Bookings',
                path: '/bookings',
                exact: true,
                authType: TypeOfAuth.Auth,
                component: Components.BookingsScreen,
                icon: <BookmarksIcon />
            }
        ]
    },
    // {
    //     label: 'Parts Calender',
    //     exact: true,
    //     path: '/Services/Parts_Calender',
    //     component: () => <Components.PartsCalender />,
    //     authType: TypeOfAuth.Auth
    // },
    {
        label: 'Reports',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <BubbleChartIcon />,
        children: [
            {
                label: 'Group Dashboard',
                exact: true,
                path: '/ReportsDashboard',
                component: Components.MiniViewReportingDashboard,
                // component: Components.RepotingTab,
                authType: TypeOfAuth.Auth,
                icon: <SpeedIcon />
            },
            {
                label: 'Detail Dashboard',
                exact: true,
                path: '/RepotingTab',
                component: Components.RepotingTab,
                authType: TypeOfAuth.Auth,
                icon: <SpeedIcon />
            },
            {
                label: 'DOC',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DescriptionIcon />,
                path: '/Docs/WeeklyFlashReport',
                component: () => <Components.WeeklyFlashReport MeasureDesc="Weekly Flash Report" />
            },
            {
                label: 'Budget Dashboard',
                exact: true,
                path: '/budget',
                component: Components.BudgetScreen,
                authType: TypeOfAuth.Auth,
                icon: <SpeedIcon />
            },
            {
                label: 'Profit & Loss',
                exact: true,
                path: '/profitloss',
                component: Components.ProfitLoss,
                authType: TypeOfAuth.Auth,
                icon: <EuroIcon />
            },
            {
                label: 'Aged Measures',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DragIndicatorIcon />,
                children: [
                    {
                        label: 'Dashboard',
                        exact: true,
                        path: '/AgedMeasures',
                        component: () => <Components.AgedMeasuresDashBoard MeasureDesc="Dashboard" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Account Queries',
                        exact: true,
                        path: '/AgedMeasures/AccountQueries',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={6} MeasureDesc="Account Queries" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Account Queries By Department',
                        exact: true,
                        path: '/AgedMeasures/AccountQueriesByDepartment',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={21} MeasureDesc="Account Queries By Department" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Aged Debt - Branch',
                        exact: true,
                        path: '/AgedMeasures/AgedDebt-Branch',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={7} MeasureDesc="Aged Debt - Branch" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Aged Debt - Company',
                        exact: true,
                        path: '/AgedMeasures/AgedDebt-Company',
                        component: () => <Components.AgedDebt MeasureDesc="Aged Debt - Company" MeasureID={8} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Cash',
                        exact: true,
                        path: '/AgedMeasures/Cash',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={5} MeasureDesc="Cash" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Cash By Department',
                        exact: true,
                        path: '/AgedMeasures/CashByDepartment',
                        component: () => <Components.CashByDepartment MeasureDesc="Cash By Department" MeasureID={20} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Surcharges',
                        exact: true,
                        path: '/AgedMeasures/DirtyUnits',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={2} MeasureDesc="Surcharges" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Fuso Stock',
                        exact: true,
                        path: '/AgedMeasures/NewFusoStock',
                        component: () => <Components.NewStocks MeasureDesc="New Fuso Stock" MeasureID={11} FC="C" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Maxus Vans',
                        exact: true,
                        path: '/AgedMeasures/NewMaxusVans',
                        component: () => <Components.NewStocks MeasureDesc="New Maxus Vans" MeasureID={18} FC="A" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Truck Stock',
                        exact: true,
                        path: '/AgedMeasures/NewTruckStock',
                        component: () => <Components.NewStocks MeasureDesc="New Truck Stock" MeasureID={10} FC="H" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Van Stock',
                        exact: true,
                        path: '/AgedMeasures/NewVanStock',
                        component: () => <Components.NewStocks MeasureDesc="New Van Stock" MeasureID={12} FC="V" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'R&M',
                        exact: true,
                        path: '/AgedMeasures/R&M',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={3} MeasureDesc="R&M" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Fuso Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedFusoStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Fuso Stock" MeasureID={14} FC="C" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Maxus Vans',
                        exact: true,
                        path: '/AgedMeasures/UsedMaxusVans',
                        component: () => <Components.UsedStocks MeasureDesc="Used Maxus Vans" MeasureID={19} FC="A" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Non-franchise Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedNon-franchiseStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Non-franchise Stock" MeasureID={16} FC="Z" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Truck Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedTruckStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Truck Stock" MeasureID={13} FC="H" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Van Stock',
                        exact: true,
                        path: '/AgedMeasures/UsedVanStock',
                        component: () => <Components.UsedStocks MeasureDesc="Used Van Stock" MeasureID={15} FC="V" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Vehicle Debt',
                        exact: true,
                        path: '/AgedMeasures/VehicleDebt',
                        component: () => <Components.AgedDebt MeasureDesc="Vehicle Debt" MeasureID={9} />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Warranty',
                        exact: true,
                        path: '/AgedMeasures/Warranty',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={4} MeasureDesc="Warranty" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Work In Progress',
                        exact: true,
                        path: '/AgedMeasures/WorkInProgress',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={1} MeasureDesc="Work In Progress" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Work In Progress by Departement',
                        exact: true,
                        path: '/AgedMeasures/WorkInProgressbyDepartement',
                        component: () => <Components.GetAllBranchByAgedMeasures MeasureID={17} MeasureDesc="Work In Progress by Departement" />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            // {
            //     label: 'New Services',
            //     exact: true,
            //     authType: TypeOfAuth.Auth,
            //     icon: <DescriptionIcon />,
            //     children: [
            //         {
            //             label: 'Dashboard',
            //             exact: true,
            //             path: '/budget_dashboard',
            //             component: Components.BudgetDashboard,
            //             authType: TypeOfAuth.Auth,
            //             icon: <SpeedIcon />
            //         }
            //     ]
            // },
            {
                label: 'Vehicle',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DescriptionIcon />,
                children: [
                    {
                        label: 'Service Due',
                        exact: true,
                        path: '/vehicle/serviceDue',
                        component: () => <Components.ReportVehiclesServiceComponent />,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    },
                    {
                        label: 'MOT Due',
                        exact: true,
                        path: '/vehicle/MOTDue',
                        component: () => <Components.ReportVehiclesMOTDueComponent />,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    },
                    {
                        label: 'Safety Check Due',
                        exact: true,
                        path: '/vehicle/safetyCheckDue',
                        component: () => <Components.ReportVehiclesSafetyCheckDueComponent />,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    },
                    {
                        label: 'Vehicle Due Calendar',
                        exact: true,
                        path: '/vehicle/VehicleDueCalendar',
                        component: () => <Components.VehicleDueCheckCalendar />,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    }
                ]
            },

            {
                label: 'Used Stock',
                children: true,
                authType: TypeOfAuth.Auth,
                icon: <EmojiEventsIcon />,
                children: [
                    {
                        label: 'Used Stock Valuation',
                        exact: true,
                        path: '/UsedStockValuation',
                        component: Components.UsedStockValuation,
                        authType: TypeOfAuth.Auth
                        // icon: <UsedStockValuation />
                    },
                    {
                        label: 'Used Vehicle Stock',
                        exact: true,
                        path: '/usedStock',
                        component: Components.UsedStock,
                        authType: TypeOfAuth.Auth
                        // icon: <UsedVehicleStock />
                    },
                    {
                        label: 'Aged Vehicle Stock',
                        exact: true,
                        path: '/agedStocks',
                        component: Components.AgedStocks,
                        authType: TypeOfAuth.Auth
                        // icon: <AgeVehicleSTock />
                    },
                    {
                        label: 'Overage Priority List',
                        exact: true,
                        path: '/overagePriority',
                        component: Components.OveragePriority,
                        authType: TypeOfAuth.Auth
                        // icon: <OveragePriorityIcon />
                    }
                ]
            },
            {
                label: 'Sales Performance',
                children: true,
                authType: TypeOfAuth.Auth,
                icon: <EmojiEventsIcon />,
                children: [
                    {
                        label: 'Sales Dashboard',
                        exact: true,
                        path: '/salesDashboard',
                        component: () => <Components.SalesDashboardSetting />,
                        authType: TypeOfAuth.Auth
                        // icon: <SpeedIcon />
                    },
                    {
                        label: 'Sales Stock',
                        exact: true,
                        path: '/SalesStockDashboard',
                        component: () => <Components.SalesStockDashboard />,
                        authType: TypeOfAuth.Auth
                        // icon: <SpeedIcon />
                    }
                ]
            },
            {
                label: 'Sales',
                children: true,
                authType: TypeOfAuth.Auth,
                icon: <EmojiEventsIcon />,
                children: [
                    {
                        label: 'Sales Dashboard',
                        exact: true,
                        path: '/sales/dashboard',
                        component: Components.SalesDashboard,
                        authType: TypeOfAuth.Auth
                    },
                    // {
                    //     label: 'Sales Dashboard V2',
                    //     exact: true,
                    //     path: '/sales/dashboardtwo',
                    //     component: Components.SalesDashboardTwo,
                    //     authType: TypeOfAuth.Auth
                    // },

                    {
                        label: 'New Vehicle Profit Report',
                        exact: true,
                        path: '/sales/NewVehicleProfitReport',
                        component: Components.NewVehicleProfitReport,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Vehicle Profit Report',
                        exact: true,
                        path: '/sales/UsedVehicleProfitReport',
                        component: Components.UsedVehicleProfitReport,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Account Queries by Salesexec',
                        exact: true,
                        path: '/sales/AccountQueriesbySalesexec',
                        component: Components.AccountQueriesbySalesexec,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Aged Measures Work - Progress',
                        exact: true,
                        path: '/sales/AgedMeasuresWorkingProgress',
                        component: Components.AgedMeasuresWorkingProgress,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Sales Work In Progress',
                        exact: true,
                        path: '/sales/SalesWorkInProgress',
                        component: Components.SalesWorkinProgress,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Registration Reports',
                        exact: true,
                        path: '/sales/RegistrationReports',
                        component: Components.RegistrationReports,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Vehicle Stock (Excluding WIP)',
                        exact: true,
                        path: '/sales/NewVehicleStockExcludingWIP',
                        component: Components.VehicleStocksExWip,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'New Vehicle Orders',
                        exact: true,
                        path: '/sales/VehicleNewOrder',
                        component: Components.VehicleNewOrder,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Outstanding Invoices',
                        exact: true,
                        path: '/sales/OutstandingInvoices',
                        component: Components.OutstandingInvoices,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Sales Contingency Debt',
                        exact: true,
                        path: '/sales/SalesContingencyDebt',
                        component: Components.SalesContingencyDebt,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used Vehicle Stock',
                        exact: true,
                        path: '/sales/UsedVehicleStock',
                        component: Components.UsedVehicleStock,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Vehicle Aged Debt By Salesexec',
                        exact: true,
                        path: '/sales/VehicleAgedDebtBySalesexec',
                        component: Components.VehicleAgedDebtBySalesexec,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },

            {
                label: 'Services',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <WebIcon />,
                children: [
                    {
                        label: 'Dashboard',
                        exact: true,
                        path: '/service/dashboard',
                        component: Components.ServiceDashboard,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Consumables',
                        exact: true,
                        path: '/Services/Consumables',
                        component: () => <Components.Consumables type="C" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Idle Time Analysis',
                        exact: true,
                        path: '/Services/IdleTimeAnalysis',
                        component: () => <Components.IdleTimeAnalysis />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Idle Time Analysis-Reason',
                        exact: true,
                        path: '/Services/IdleTimeAnalysisReason',
                        component: () => <Components.IdleTimeAnalysisReason />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Invoiced Hours',
                        exact: true,
                        path: '/Services/InvoicedHours',
                        component: Components.InvoicedHours,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Uninvoiced Hours',
                        exact: true,
                        path: '/Services/UninvoicedHours',
                        component: Components.UninvoicedHours,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Credit Hours',
                        exact: true,
                        path: '/Services/CreditsHours',
                        component: Components.CreditsHours,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Mobilovan',
                        exact: true,
                        path: '/Services/Mobilovan',
                        component: () => <Components.Consumables type="M" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'NPW Rectification',
                        exact: true,
                        path: '/Services/NPWRectification',
                        component: () => <Components.Consumables type="R" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Parts Warranty Write Off',
                        exact: true,
                        path: '/Services/PartsWarrantyWriteOff',
                        component: () => <Components.Consumables type="P" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Service Warranty Write Off',
                        exact: true,
                        path: '/Services/ServiceWarrantyWriteOff',
                        component: () => <Components.Consumables type="S" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Technician Efficiency',
                        exact: true,
                        path: '/Services/Efficiency',
                        component: () => <Components.TechnicianEfficiency />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Invoiced Hours Calender',
                        exact: true,
                        path: '/Services/InvoicedhoursCalender',
                        component: () => <Components.InvoiceHoursCalendar />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Parts',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <BuildIcon />,
                children: [
                    // {
                    //     label: 'Dashboard',
                    //     exact: true,
                    //     path: '/parts/dashboard',
                    //     component: Components.HeadLinesDashboard
                    // },
                    {
                        label: 'Parts',
                        exact: true,
                        path: '/parts/PartsSales/P',
                        component: () => <Components.PartSales code="P" desc="Part Sales - Cash Sales (P)" />,
                        authType: TypeOfAuth.Auth
                    },

                    {
                        label: 'Trade Centre',
                        exact: true,
                        path: '/parts/PartsSales/B',
                        component: () => <Components.PartSales code="B" desc="Part Sales - Trade Center (B)" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Workshop',
                        exact: true,
                        path: '/parts/PartsSales/w',
                        component: () => <Components.PartSales code="W" desc="Part Sales - Workshop Center (w)" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Car Express',
                        exact: true,
                        path: '/parts/PartsSales/f',
                        component: () => <Components.PartSales code="F" desc="Part Sales - Workshop Center (w)" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Total',
                        exact: true,
                        path: '/parts/PartsSales/A',
                        component: () => <Components.PartSales code="A" desc="Part Sales - Total" />,
                        authType: TypeOfAuth.Auth
                    },
                    {
                        label: 'Used',
                        exact: true,
                        path: '/parts/PartsSales/U',
                        component: () => <Components.PartSales code="U" desc="Part Sales - Used (U)" />,
                        authType: TypeOfAuth.Auth
                    }
                ]
            },
            {
                label: 'Workshop',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <DescriptionIcon />,
                children: [
                    {
                        label: 'Shift Calendar',
                        exact: true,
                        path: '/ShiftCalendar',
                        component: Components.ShiftCalenderComp,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    },
                    {
                        label: 'Resource Calendar',
                        exact: true,
                        path: '/ResourceCalendar',
                        component: Components.ResourceCalenderComp,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeedIcon />
                    }
                ]
            },
            // {
            //     label: 'WIP Hours',
            //     exact: true,
            //     path: '/wipHours',
            //     component: Components.WipHours,
            //     authType: TypeOfAuth.Auth,
            //     icon: <SpeedIcon />
            // },
            // {
            //     label: 'Sales2',
            //     authType: TypeOfAuth.Auth,
            //     children: [
            //         {
            //             label: 'Sales Dashboard',
            //             exact: true,
            //             path: '/salesDashboard',
            //             component: () => <Components.SalesDashboardSetting />,
            //             authType: TypeOfAuth.Auth,
            //             icon: <SpeedIcon />
            //         },
            //         {
            //             label: 'Stocking Charges',
            //             exact: true,
            //             path: '/stockingCharges',
            //             component: () => <Components.StokingChargesScreen />,
            //             authType: TypeOfAuth.Auth,
            //             icon: <SpeedIcon />
            //         }
            //     ]
            // },
            {
                label: 'Settings',
                authType: TypeOfAuth.Auth,
                children: [
                    {
                        label: 'Branch Settings',
                        path: '/branch_Codes',
                        exact: true,
                        component: Components.Braches,
                        authType: TypeOfAuth.Auth,
                        icon: <BuildIcon />
                    },
                    {
                        label: 'Sales Performance ',
                        authType: TypeOfAuth.Auth,
                        children: [
                            {
                                label: 'New Sales Exec Targets',
                                exact: true,
                                path: '/NewSalesCategoryBudget',
                                component: () => <Components.NewUsedSalesCategoryBudget vehicleType={'N'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Used Sales Exec Targets',
                                exact: true,
                                path: '/UsedVehicleReportingBudget',
                                component: () => <Components.NewUsedSalesCategoryBudget vehicleType={'U'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'New Vehicle Internal Budget',
                                exact: true,
                                path: '/NewVehicleReportingInternalBudget',
                                component: () => <Components.NewUsedReportingInternalBudget vehicleType={'N'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Used Vehicle Internal Budget',
                                exact: true,
                                path: '/UsedVehicleReportingInternalBudget',
                                component: () => <Components.NewUsedReportingInternalBudget vehicleType={'U'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Finance Provider Intrest Rate',
                                expect: true,
                                path: '/FP_IntrestRate_Provider',
                                component: Components.FPIntrestRateProvider,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },

                            {
                                label: 'Finance Penetration Accounts',
                                exact: true,
                                path: '/Supplier',
                                component: () => <Components.FinanceCustomer />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'League Table Points',
                                exact: true,
                                path: '/league_table_points',
                                component: () => <Components.LeagueTableScreen />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Franchises',
                                exact: true,
                                path: '/franchise',
                                component: () => <Components.FranchiseScreen />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Sales Exec',
                                path: '/Lists/SalesExec',
                                exact: true,
                                component: Components.SalesExec,
                                authType: TypeOfAuth.Auth
                            }
                        ]
                    },

                    {
                        label: 'Budget',
                        authType: TypeOfAuth.Auth,
                        children: [
                            {
                                label: 'After Sales Vehicle Budget',
                                exact: true,
                                path: '/ReportingBudget',
                                component: Components.ReportingBudget,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'New Vehicle Budget',
                                exact: true,
                                path: '/NewVehicleReportingBudget',
                                component: () => <Components.NewUsedReportingBudget vehicleType={'N'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            },
                            {
                                label: 'Used Vehicle Budget',
                                exact: true,
                                path: '/UsedSalesCategoryBudget',
                                component: () => <Components.NewUsedReportingBudget vehicleType={'U'} />,
                                authType: TypeOfAuth.Auth,
                                icon: <SpeedIcon />
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'Invoices',
        exact: true,
        authType: TypeOfAuth.Auth,
        icon: <ReceiptIcon />,
        children: [
            {
                label: 'Dashboard',
                exact: true,
                path: '/invoice/dashboard',
                component: () => <Components.InvoicesDashboard />,
                authType: TypeOfAuth.Auth,
                icon: <HomeIcon />
            },
            {
                label: 'Customers',
                exact: true,
                path: '/invoice/Customers',
                component: () => <Components.InvoiceCustomersListScreen />,
                authType: TypeOfAuth.Auth,
                icon: <AccountBoxIcon />
            },

            {
                label: 'Invoices',
                exact: true,
                authType: TypeOfAuth.Auth,
                icon: <ForumIcon />,
                children: [
                    {
                        label: 'All Invoices',
                        exact: true,
                        path: '/invoice/allInvoices',
                        component: () => <Components.InvoiceScreens />,
                        authType: TypeOfAuth.Auth,
                        icon: <FileCopyIcon />
                    },
                    {
                        label: 'Invoices Outstanding',
                        exact: true,
                        path: '/invoice/Invoice_Outstanding',
                        component: () => <Components.InvoiceScreens status={1} />,
                        authType: TypeOfAuth.Auth,
                        icon: <ReceiptIcon />
                    },
                    {
                        label: 'Invoices Paid',
                        exact: true,
                        path: '/invoice/Invoice_Paid',
                        component: () => <Components.InvoiceScreens status={3} />,
                        authType: TypeOfAuth.Auth,
                        icon: <PaymentIcon />
                    },
                    {
                        label: 'Invoices Queried',
                        exact: true,
                        path: '/invoice/Invoice_Queried',
                        component: () => <Components.InvoiceScreens status={2} />,
                        authType: TypeOfAuth.Auth,
                        icon: <RemoveFromQueueIcon />
                    },
                    {
                        label: 'Messages Sent',
                        exact: true,
                        path: '/invoice/messagesSent',
                        component: () => <Components.InvoiceScreens status={-2} />,
                        authType: TypeOfAuth.Auth,
                        icon: <RateReviewIcon />
                    },
                    {
                        label: 'Messages Received',
                        exact: true,
                        path: '/invoice/messagesReceived',
                        component: () => <Components.InvoiceScreens status={-1} />,
                        authType: TypeOfAuth.Auth,
                        icon: <SpeakerNotesIcon />
                    }
                ]
            }
        ]
    },
    {
        label: 'Appraisal',
        exact: true,
        path: '/Appraisal_Dashboard',
        icon: <DriveEtaIcon />,
        component: Components.ApprisalMaindashboard,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'MOT HISTORY',
        // hidden: true,
        icon: <DriveEtaIcon />,
        exact: true,
        path: '/MotHistoryAndRoadTax',
        component: Components.MotHistoryScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'BookingsList',
        path: '/BookingsRequesteList',
        exact: true,
        authType: TypeOfAuth.Auth,
        component: () => <Components.RequestedBookings status="1" />,
        icon: <EmojiTransportationIcon />,
        hidden: true
    },
    {
        label: 'GetAgedMeasuresDirtyUnits',
        exact: true,
        path: '/AgedMeasures/GetAgedMeasuresDirtyUnits',
        component: () => <Components.GetAgedMeasuresDirtyUnits />,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'ViewDebtDetailsSalesexec',
        exact: true,
        path: '/sales/ViewDebtDetailsSalesexec',
        component: () => <Components.ViewDebtDetailsSalesexec />,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'GetAgedMeasuresRanMWarrantyCashGen',
        exact: true,
        path: '/AgedMeasures/GetAgedMeasuresRanMWarrantyCashGen',
        component: () => <Components.GetAgedMeasuresRanMWarrantyCashGen />,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'GetUsedAndNewVehicleDebt',
        exact: true,
        path: '/AgedMeasures/GetUsedAndNewVehicleDebt',
        component: () => <Components.GetUsedAndNewVehicleDebt />,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'EnquirieList',
        exact: true,
        path: '/AgedMeasures/EnquirieList',
        component: Components.EnquirieList,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Workflow Job',
        path: '/job/:jobId/:workflowId',
        component: (props) => <Components.workflowJobDetails {...props} ProcessId={1} />,
        hidden: true,
        exact: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'New Workflow Job',
        path: '/NewJob/:stockID/:workflowId',
        component: (props) => <Components.WorkflowNewJobDetails {...props} ProcessId={1} />,
        hidden: true,
        exact: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Used Workflow Job',
        path: '/UsedJob/:stockID/:workflowId',
        component: (props) => <Components.WorkflowUsedJobDetails {...props} ProcessId={1} />,
        hidden: true,
        exact: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Tech Dashboard Detail',
        path: '/techDashgrid',
        exact: true,
        component: Components.TechDashboardGrid,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Logout',
        path: '/logout',
        hidden: true,
        component: Components.logout,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'HistoryModal',
        path: '/SpecialEquipmentHistoryModal',
        hidden: true,
        component: Components.SpecialEquipmentHistoryModal,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'VanDetails',
        path: '/sales_newRegistration',
        hidden: true,
        component: Components.SalesNewRegistration,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Order Intek',
        path: '/sales_orderIntek',
        hidden: true,
        component: Components.VehicleSalesOrderIntake,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Used Invoice',
        path: '/sales_usedInvoice',
        hidden: true,
        component: Components.VehicleSalesUsedInvoice,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Ev_target',
        path: '/sales_EvTarget',
        hidden: true,
        component: Components.VehicleSalesEvTarget,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Authentication',
        path: '/auth',
        hidden: true,
        component: Components.AuthenticationScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Authentication Verification',
        path: '/key',
        hidden: true,
        component: Components.TwoAuthDiasbledScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'User Profile',
        path: '/profile',
        hidden: true,
        component: Components.UserProfile,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales Performance Registration',
        path: '/salesPefromance/Registration',
        hidden: true,
        component: Components.RegistrationQuaterTable,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales Performance invoiced',
        path: '/salesPefromance/invoiced',
        hidden: true,
        component: Components.InvoiceQuaterTable,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales Performance Order Take',
        path: '/salesPefromance/orderTake',
        hidden: true,
        component: Components.OrderTakeScreen,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales Performance Used Stock',
        path: '/salesPefromance/usedStock',
        hidden: true,
        component: Components.SalesPerformanceUsedStock,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Sales Performance New Stock',
        path: '/salesPefromance/newStock',
        hidden: true,
        component: Components.SalesPerformanceNewStock,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Service 24',
        hidden: true,
        exact: true,
        path: '/addupdate/service24/:HeaderRecordID?/:service24JobID',
        component: Components.Service24Modal,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'League Detail',
        exact: true,
        hidden: true,
        path: '/league_Detail',
        component: () => <Components.LeagueDetailScreen />,
        authType: TypeOfAuth.Auth,
        icon: <SpeedIcon />
    },
    {
        label: 'Technician Calender',
        exact: true,
        hidden: true,
        path: '/TechnicianCalender',
        component: () => <Components.TechnicianCalender />,
        authType: TypeOfAuth.Auth,
        icon: <SpeedIcon />
    },
    // {
    //     label: 'Appraisal Customer Details',
    //     exact: true,
    //     hidden: true,
    //     path: '/Appraisal_Cust_Details',
    //     component: () => <Components.CustDetailsForm />,
    //     authType: TypeOfAuth.Auth,
    //     icon: <SpeedIcon />
    // },
    // {
    //     label: 'Appraisal Create Enquiry',
    //     exact: true,
    //     hidden: true,
    //     path: '/Appraisal_createEnquiry',
    //     component: () => <Components.CreateEnquiry />,
    //     authType: TypeOfAuth.Auth,
    //     icon: <SpeedIcon />
    // }
    {
        label: 'Create APP',
        exact: true,
        hidden: true,
        path: '/Appraisal_createAppraisal',
        component: () => <Components.CreateAppraisal />,
        authType: TypeOfAuth.Auth,
        icon: <SpeedIcon />
    },
    {
        label: 'All Appraisal',
        icon: <DriveEtaIcon />,
        exact: true,
        hidden: true,
        path: '/appraisal_Details',
        component: () => <Components.AppraisalDashboard />,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Trade Centre',
        icon: <DriveEtaIcon />,
        exact: true,
        hidden: true,
        path: '/TradeCentre?desc=TradeCentre=c',
        component: () => <Components.PartSales />,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Workshop',
        icon: <DriveEtaIcon />,
        exact: true,
        hidden: true,
        path: '/Workshop?desc=Workshop&code=W',
        component: () => <Components.PartSales />,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'All Parts ',
        icon: <DriveEtaIcon />,
        exact: true,
        hidden: true,
        path: '/Total?desc=Total&code=MT',
        component: () => <Components.PartSales />,
        authType: TypeOfAuth.Auth
    }

    //     {
    //         label: 'Awaiting Approval',
    //         icon: <DriveEtaIcon />,
    //         exact: true,
    //         path: '/Awaiting Approval?screen=Awaiting Approval&Id=1',
    //         component: () => <Components.AppraisalDashboard id={1} />,
    //         authType: TypeOfAuth.Auth
    //     },
    //     {
    //         label: 'Awaiting Review',
    //         icon: <DriveEtaIcon />,
    //         exact: true,
    //         path: '/Awaiting Review?screen=Awaiting Review&Id=2',
    //         component: () => <Components.AppraisalDashboard id={2} />,
    //         authType: TypeOfAuth.Auth
    //     },
    //     {
    //         label: 'Appraisal Completed',
    //         icon: <DriveEtaIcon />,
    //         exact: true,
    //         path: '/Appraisal Completed?screen=Appraisal Completed&Id=3',
    //         component: () => <Components.AppraisalDashboard id={3} />,
    //         authType: TypeOfAuth.Auth
    //     },
    //     {
    //         label: 'Under Review',
    //         icon: <DriveEtaIcon />,
    //         exact: true,
    //         path: '/Under Review?screen=Under Review&Id=4',
    //         component: () => <Components.AppraisalDashboard id={4} />,
    //         authType: TypeOfAuth.Auth
    //     }
];
